export enum Family {
  IceFairy = 1,
  Elemental,
  IceFangs,
  Nihilist,
  IceGuard,
  ChildrenOfDawn,
  Sentinel,
  Dancer,
  Musician,
  Yhdorian,
  Peasant,
  Legion4,
  Artillery,
  Legion6,
  Mercenary,
  Rat,
  Blacksmith,
  Troll,
  Harpy,
  ShamanRat
}
